*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.node-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.react-dropdown-tree-select {
  width: 300px;
  max-height: 100px;
}

.react-dropdown-tree-select .react-dropdown-tree-select {
  max-height: 100px !important;
}

.dropdown .dropdown-trigger {
  width: 230px;
  height: 40px;
  max-height: 100px !important;
  border-radius: 50px;
}

.dropdown .dropdown-trigger .tag-list .search {
  width: 100%;
  border: none;
  height: 30px;
  background-color: transparent;
}

.dropdown .dropdown-content {
  width: 230px;
}


.react-dropdown-tree-select {
  width: fit-content;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  float: right !important;
  margin-top: 10px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  float: right;
  margin-top: 10px;
}




.categorySelect .dropdown .dropdown-content {
  width: 350px;
  text-align: left;
}

.categorySelect .dropdown .dropdown-trigger {
  text-align: left;
  width: 350px;
  height: 40px;
  max-height: 100px !important;
  border-radius: 50px;
}

.MuiMultiSectionDigitalClockSection-root {
  width: 80px !important;
}